import { defineMessages } from "react-intl";
import { toCityId, toDistrictId } from "components/LocationFormSection/messages";

export const scope = "app.containers.LandingPage.DistributionLocationPage";

export const toBranchId = (city, district, branch) => ({
  id: `location.${city}.districts.${district}.branch.${branch}`,
})

export { toCityId, toDistrictId };

export default defineMessages({
  address: {
    id: `${scope}.address`,
    defaultMessage: "Địa chỉ",
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: "Thành phố",
  },
  district: {
    id: `${scope}.district`,
    defaultMessage: "Quận",
  },
  branch: {
    id: `${scope}.branch`,
    defaultMessage: "Chi nhánh",
  },
  others: {
    id: `${scope}.others`,
    defaultMessage: "Khác",
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: "Tìm kiếm địa điểm phân phối các sản phẩm RTE",
  },
  closestPosition: {
    id: `${scope}.closestLocation`,
    defaultMessage: "Vị trí gần nhất",
  },
  getDirection: {
    id: `${scope}.getDirection`,
    defaultMessage: "Chỉ đường",
  },
  notFound: {
    id: `${scope}.notFound`,
    defaultMessage: "Không có kết quả",
  }
});