import { useState, useMemo, useRef, useEffect } from 'react';
import useDeepCompareEffectForMaps from './useDeepCompareEffectForMaps';
import { Coords } from '../../../type.d';

const useMapContainer = (coords: Coords) => {
  const [mapContainer, setMapContainer] = useState<google.maps.Map | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const mapOptions: google.maps.MapOptions = useMemo(
    () => ({
      center: coords,
      mapId: '4c94d84f794d632',
      zoom: 15,
      disableDefaultUI: true,
      zoomControl: true,
      mapTypeControl: true,
      clickableIcons: true,
    }),
    [coords?.lat, coords?.lng],
  );

  // useEffect(() => {
  //   if (mapContainer) {
  //     google.maps.event.addListenerOnce(mapContainer, 'idle', () => {
  //       google.maps.event.trigger(mapContainer, 'resize');
  //       mapContainer.setCenter(coords); // Re-center after resize
  //     });
  //   }
  // }, [mapContainer, coords?.lat, coords?.lng]);

  useEffect(() => {
    if (ref.current && !mapContainer) {
      setMapContainer(new window.google.maps.Map(ref.current, mapOptions));
    }
  }, [ref, mapContainer, mapOptions]);

  useDeepCompareEffectForMaps(() => {
    if (mapContainer) {
      mapContainer.setOptions(mapOptions);
      google.maps.event.addListenerOnce(mapContainer, 'idle', () => {
        google.maps.event.trigger(mapContainer, 'resize');
        mapContainer.setCenter(coords); // Re-center after resize
      });
    }
  }, [mapContainer, mapOptions]);

  return { mapContainer: mapContainer, ref: ref };
};

export default useMapContainer;
