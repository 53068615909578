import React, { useState, useMemo, useEffect } from 'react';
import FilterCombo from 'components/FilterCombo';
import { LocationSearch } from '../../type.d';
import { DEFAULT_CITY } from '../../constants';

interface Props {
  form: any;
  selectionList: any;
  getBranchByCityAndDistrict: any;
  resetDistrictAndBranch: any;
  getDistrictsByCity: any;
  resetBranch: any;
  onFilter: (data: Partial<LocationSearch>) => void;
  prefixNames: any;
}

const Filters = ({
  form,
  selectionList,
  getDistrictsByCity,
  resetDistrictAndBranch,
  getBranchByCityAndDistrict,
  resetBranch,
  onFilter,
  prefixNames,
}: Props) => {
  const { getFieldValue } = form;
  const [districtList, setDistrictList] = useState<any>([]);
  const [branchList, setBranchList] = useState<any>([]);

  useEffect(() => {
    const city = DEFAULT_CITY ? DEFAULT_CITY : getFieldValue(prefixNames)?.city;
    if (city) {
      const districtData = getDistrictsByCity(city);
      setDistrictList(districtData);
      resetDistrictAndBranch();
      onFilter({ city: city });
    }
  }, []);

  const onChangeCity = (value: string) => {
    const districtData = getDistrictsByCity(value);
    setDistrictList(districtData);
    resetDistrictAndBranch();
    onFilter({ city: value });
  };
  const onChangeDistrict = (value: string) => {
    const branchData = getBranchByCityAndDistrict(getFieldValue(prefixNames)?.city, value);
    setBranchList(branchData);
    resetBranch();
    onFilter({ city: DEFAULT_CITY ? DEFAULT_CITY : getFieldValue(prefixNames)?.city, district: value });
  };

  const onChangeBranch = (value: string) => {
    onFilter({
      city: DEFAULT_CITY ? DEFAULT_CITY : getFieldValue(prefixNames)?.city,
      district: getFieldValue(prefixNames)?.district,
      branch: value,
    });
  };

  const refinedSelectionList = useMemo(
    () =>
      selectionList.map((selection) => {
        if (selection.name === 'city' && !DEFAULT_CITY) {
          selection.onChange = onChangeCity;
        }
        if (selection.name === 'district') {
          selection.onChange = onChangeDistrict;
          selection.values = districtList;
        }
        if (selection.name === 'branch') {
          selection.onChange = onChangeBranch;
          selection.values = branchList;
        }
        return selection;
      }),
    [districtList, branchList, selectionList],
  );
  return <FilterCombo selectionList={refinedSelectionList} prefixNames={prefixNames} />;
};

export default Filters;
