import React, { FC, Fragment, useCallback, useMemo } from 'react';
import { ApplicationRootState } from 'types';
import { createStructuredSelector } from 'reselect';
import { ContainerState, LocationSearch } from './type.d';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import saga from './saga';
import reducer from './reducer';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import selectDistributionLocationPage from './selectors';
import { applyFilter, applySearch, currentPosition } from './actions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styles/styled-components';
import Helmet from 'react-helmet';
import { Viewport } from 'components/ViewportProvider';
import { mainBanner, mainBannerEn, mainBannerMobile, mainBannerMobileEn } from './images';
import SearchOutput from './components/SearchOutput';
import PageHeader from 'components/PageHeader/PageHeader';
import { Form, Image, Spin } from 'antd';
import { SelectionList } from 'components/FilterCombo';
// import Button from 'components/Button/Button';
// import { AimOutlined } from '@ant-design/icons';
import Filters from './components/Filters';
import Select from 'components/Select';
import { SelectItem } from 'components/Select/Select';
import media from 'utils/mediaStyle';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { selectLang } from 'containers/MainLayout/selectors';

export interface IStateProps {
  distributionLocationPage: ContainerState;
  lang: string;
}

export interface IDispatchProps {
  onFilter: (data: Partial<LocationSearch>) => void;
  onSearch: (data: Partial<LocationSearch>) => void;
  getCurrentPosition: () => void;
}

type Props = IStateProps & IDispatchProps & RouteComponentProps;

const Banner = styled(Image)`
  display: block;
  object-fit: cover;
  max-height: 320px;
`;

const Title = styled(PageHeader)`
  margin: 0;
  margin-top: 20px;
  padding-inline: 16px;
  ${({ asComponent }) => {
    return `
     ${asComponent ?? 'h1'} {
      font-size: 16px;
      font-weight: 500;
      line-height: 32px;
    }   
  `;
  }};
  ${media.md`
    padding: 0;
    margin-top: 40px;
    ${({ asComponent }) => {
      return `
        ${asComponent ?? 'h1'} {
          font-size: 24px;
        }
      `;
    }}
  `};
`;

const AForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-block: 12px;
  gap: 16px;
  ${media.md`
    justify-content: space-around;
    flex-direction: row;
    margin-block: 20px;
  `}
  & > .ant-form-item {
    flex: 1;
    margin-bottom: 0;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 75pt);
  margin-top: 99.66px;
`;

const Content = styled.div`
  padding: 0 16px;
  ${media.md`
    padding: 0;`}
`;

const initialSelectionList: SelectionList[] = [
  {
    name: 'city',
    // label: 'Thành phố',
    placeholder: 'Chọn thành phố',
    initialValue: 'Hồ Chí Minh',
    Component: Select,
    onChange: () => {},
    values: [],
  },
  {
    name: 'district',
    // label: 'Quận',
    placeholder: 'Chọn quận',
    Component: Select,
    onChange: () => {},
    values: [],
  },
  {
    name: 'branch',
    // label: 'Chi nhánh',
    placeholder: 'Chọn chi nhánh',
    Component: Select,
    onChange: () => {},
    values: [],
  },
];

const DistributionLocationPage: FC<Props> = (props) => {
  const { distributionLocationPage, onFilter, onSearch, lang, getCurrentPosition } = props;

  const { distribution, search, loading } = distributionLocationPage;

  const [form] = Form.useForm();

  const { data: filterData, locations: filteredLocations } = distribution;

  const getRightBanner = (width: number) => {
    if (lang === 'vi') {
      return width >= 768 ? mainBanner : mainBannerMobile;
    }
    return width >= 768 ? mainBannerEn : mainBannerMobileEn;
  };

  const selectionList = useMemo(() => {
    const cities = Object.keys(filterData[0] || {}).map((city) => ({
      value: city,
      label: city,
    }));

    initialSelectionList[0].values = cities;
    return initialSelectionList;
  }, [filterData.length]);

  const getDistrictsByCity = useCallback(
    (city: string): SelectItem[] => {
      return Object.keys(filterData[0]?.[city]?.districts || {}).map((district) => ({
        value: district,
        label: district,
      }));
    },
    [filterData.length],
  );

  const getBranchByCityAndDistrict = useCallback(
    (city: string, district: string): SelectItem[] => {
      return Object.keys(filterData[0]?.[city]?.districts?.[district]?.branch || {}).map((branch) => ({
        value: branch,
        label: branch,
      }));
    },
    [filterData.length],
  );

  const resetDistrictAndBranch = useCallback(() => {
    const formValue = form?.getFieldValue('distribution-location');
    return form?.setFieldValue('distribution-location', {
      ...formValue,
      district: undefined,
      branch: undefined,
    });
  }, []);

  const resetBranch = useCallback(() => {
    const formValue = form?.getFieldValue('distribution-location');
    return form?.setFieldValue('distribution-location', {
      ...formValue,
      branch: undefined,
    });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Địa Điểm phân phối</title>
        <meta name="description" content="Điểm phân phối" />
      </Helmet>
      <Viewport.Consumer>
        {({ width }) => (
          <MainContainer>
            <Banner preview={false} src={getRightBanner(width)} alt="Distribution Locations Main Banner" fallback="" />
            <Title asComponent="h1">
              <FormattedMessage {...messages.title} />
            </Title>
            <Content>
              <Spin spinning={search.currentLocationLoading || loading}>
                <AForm
                  name="distribution-location"
                  layout={width > 768 ? 'inline' : 'vertical'}
                  form={form}
                  className="filter-form"
                  onFinish={() => {}}
                  onFinishFailed={() => {}}
                  size="large"
                >
                  {/* <FilterComboGroup prefix={['distribution-location']}>
                    <Form.Item name="current-location">
                      <Button
                        htmlType="button"
                        type="default"
                        icon={<AimOutlined />}
                        iconPosition="start"
                        block
                        onClick={getCurrentPosition}
                        style={{ justifyContent: 'start' }}
                      >
                        <FormattedMessage {...messages.closestPosition} />
                      </Button>
                    </Form.Item>
                  </FilterComboGroup> */}
                  {filterData.length ? (
                    <Filters
                      form={form}
                      selectionList={selectionList}
                      prefixNames={['distribution-location']}
                      getDistrictsByCity={getDistrictsByCity}
                      getBranchByCityAndDistrict={getBranchByCityAndDistrict}
                      resetDistrictAndBranch={resetDistrictAndBranch}
                      resetBranch={resetBranch}
                      onFilter={onFilter}
                    />
                  ) : null}
                </AForm>
              </Spin>
              <SearchOutput
                form={form}
                filteredLocations={filteredLocations}
                onSearch={onSearch}
                spinning={search.currentLocationLoading || loading}
                lang={lang}
              />
            </Content>
          </MainContainer>
        )}
      </Viewport.Consumer>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  distributionLocationPage: selectDistributionLocationPage(),
  lang: selectLang(),
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onFilter: (data: Partial<LocationSearch>) => dispatch(applyFilter(data)),
    onSearch: (data: Partial<LocationSearch>) => dispatch(applySearch(data)),
    getCurrentPosition: () => dispatch(currentPosition.request()),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withRouterComponent = withRouter(DistributionLocationPage);

const withReducer = injectReducer({ key: 'distributionLocationPage', reducer: reducer });

const withSaga = injectSaga({ key: 'distributionLocationPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(withRouterComponent);
