import { action, createAsyncAction } from 'typesafe-actions';

import { ActionTypes } from './constants';

/**
 * DistributionLocationPage actions
 */

export const applyFilter = (data: any) => action(ActionTypes.SET_FILTER, data);
export const applySearch = (data: any) => action(ActionTypes.SET_SEARCH, data);
export const setSelectedLocation = (data: any) => action(ActionTypes.SET_SELECTED_LOCATION, data);

export const fillFilterData = (data: any) => action(ActionTypes.FILL_FILTER_DATA, data);

export const currentPosition = createAsyncAction(
  ActionTypes.GET_CURRENT_LOCATION_REQUEST,
  ActionTypes.GET_CURRENT_LOCATION_SUCCESS,
  ActionTypes.GET_CURRENT_LOCATION_FAILURE,
)<void, {}, Error>();

export const distributionLocation = createAsyncAction(
  ActionTypes.DISTRIBUTION_LOCATION_REQUEST,
  ActionTypes.DISTRIBUTION_LOCATION_SUCCESS,
  ActionTypes.DISTRIBUTION_LOCATION_FAILURE,
)<void, {}, Error>();
